// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-diseases-index-js": () => import("./../../../src/pages/diseases/index.js" /* webpackChunkName: "component---src-pages-diseases-index-js" */),
  "component---src-pages-exams-index-js": () => import("./../../../src/pages/exams/index.js" /* webpackChunkName: "component---src-pages-exams-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-schedule-index-js": () => import("./../../../src/pages/schedule/index.js" /* webpackChunkName: "component---src-pages-schedule-index-js" */),
  "component---src-pages-specialties-index-js": () => import("./../../../src/pages/specialties/index.js" /* webpackChunkName: "component---src-pages-specialties-index-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-transanalsurgery-index-js": () => import("./../../../src/pages/transanalsurgery/index.js" /* webpackChunkName: "component---src-pages-transanalsurgery-index-js" */),
  "component---src-pages-workplaces-index-js": () => import("./../../../src/pages/workplaces/index.js" /* webpackChunkName: "component---src-pages-workplaces-index-js" */),
  "component---src-templates-doctor-js": () => import("./../../../src/templates/doctor.js" /* webpackChunkName: "component---src-templates-doctor-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

